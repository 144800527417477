@import url(https://fonts.googleapis.com/css?family=Sigmar+One);
@import url(https://fonts.googleapis.com/css?family=Permanent+Marker);
html,
body {
  height: 100%;
  background-color: #333;
  color: #FFF; }

a,
a:focus,
a:hover {
  color: #fff; }

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none;
  background-color: #fff;
  border: .05rem solid #fff; }

.navbar {
  display: flex;
  align-items: center;
  justify-content: center; }
  .navbar.navbar-dark {
    background: #292b2c !important; }
  .navbar .navbar-brand {
    font-family: 'Permanent Marker', cursive;
    font-size: 2rem;
    padding: 0; }

.card-image {
  background-color: #292b2c; }
  .card-image img {
    cursor: pointer; }

.card-columns {
  -webkit-column-count: 6;
          column-count: 6; }
  @media screen and (max-width: 74.9em) {
    .card-columns {
      -webkit-column-count: 4;
              column-count: 4; } }
  @media screen and (max-width: 61.9em) {
    .card-columns {
      -webkit-column-count: 3;
              column-count: 3; } }
  @media screen and (max-width: 47.9em) {
    .card-columns {
      -webkit-column-count: 2;
              column-count: 2; } }
  @media screen and (max-width: 33.9em) {
    .card-columns {
      -webkit-column-count: 1;
              column-count: 1; } }
  .card-columns .card-image {
    text-align: center; }

.react-loading {
  margin: 0 auto; }

