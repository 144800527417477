@import url('https://fonts.googleapis.com/css?family=Sigmar+One');
@import url('https://fonts.googleapis.com/css?family=Permanent+Marker');

.navbar {
  display: flex;
  align-items: center;
  justify-content: center;

  &.navbar-dark {
    background: #292b2c !important;
  }
  .navbar-brand {
    //font-family: 'Sigmar One', cursive;
    font-family: 'Permanent Marker', cursive;
    font-size: 2rem;
    padding: 0;
  }
}