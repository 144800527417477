html,
body {
  height: 100%;
  background-color: #333;
  color: #FFF;
}

a,
a:focus,
a:hover {
  color: #fff;
}

.btn-secondary,
.btn-secondary:hover,
.btn-secondary:focus {
  color: #333;
  text-shadow: none;
  background-color: #fff;
  border: .05rem solid #fff;
}