.card-columns {
  column-count: 6;

  @media screen and (max-width: 74.9em) {
    column-count: 4;
  }
  @media screen and (max-width: 61.9em) {
    column-count: 3;
  }
  @media screen and (max-width: 47.9em) {
    column-count: 2;
  }
  @media screen and (max-width: 33.9em) {
    column-count: 1;
  }

  .card-image {
    text-align: center;
  }
}

.react-loading {
  margin: 0 auto;
}